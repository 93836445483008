<template>
  <div class="login-page">
    <auth-layout>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="auth-form" @submit.prevent="handleSubmit(onLogin)">
          <p>تسجيل الدخول</p>
          <ValidationProvider name="رقم الموبايل" :rules="{ required: true, digits: 12, regex: /^9639\d{8}$/ }"
            v-slot="{ errors, classes }">
            <!-- placeholder="أدخل رقم الموبايل" -->
            <input :class="classes" type="text" v-model="authForm.phoneNumber" placeholder="963-999999999">
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="كلمة المرور" rules="required|min:6" v-slot="{ errors, classes }">
            <input :class="classes" type="password" v-model="authForm.password" placeholder="أدخل كلمة المرور">
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <zadi-button :loader="loginLoading" title="تسجيل الدخول" />
          <router-link :to="{ name: 'ZadiRequestNewPassword' }">
            هل نسيت كلمة المرور ؟
          </router-link>
        </form>
      </ValidationObserver>
    </auth-layout>
  </div>
</template>

<script>
import AuthLayout from '../../layout/AuthLayout'
import ZadiButton from '../../components/shared/ZadiButton'
import auth from '../../helpers/auth.js';

export default {
  metaInfo: {
    title: "مشروع زادي | تسجيل دخول ",
  },
  components: { ZadiButton, AuthLayout },
  data() {
    return {
      authForm: {
        phoneNumber: null,
        password: null,
      },
    }
  },
  computed: {
    loginLoading() {
      return auth.isLoading;
    }
  },
  methods: {
    onLogin() {
      auth.login(this.authForm)
    }
  },
};
</script>

<style scoped>
.auth-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.auth-form p {
  color: #9A9A9A;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 30px;
}

.auth-form>span {
  display: flex;
  flex-direction: column;
}

.auth-form>span span {
  color: #dc3545;
  font-size: 15px;
  padding: 5px 0px 0px 0px;
}

.auth-form input {
  padding: 10px;
  border: 1px solid #C1C1C1;
  appearance: none;
  outline: none;
  border-radius: 6px;
  color: #515667;
}

.auth-form input.invalid {
  border: 1px solid #dc3545;
}

.auth-form a {
  color: #515667;
  font-weight: 500;
}
</style>
